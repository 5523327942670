.react-select {
    background-color: $input-solid-bg;
    color: $input-solid-color;
}

.react-select>div {
    background-color: $input-solid-bg;
    border: 1px solid $input-border-color;
}

.react-select>div>div {
    border-color: $input-solid-bg-focus;
    color: $input-solid-color;
    transition: $transition-input;
}

.react-select>div>div>div {
    color: $input-solid-color;
}
.react-select .select__option--is-selected {
    color: var(--bs-gray-800);
}