.c-tmpl-card {
    width: 300px;
    height: 250px;
    position: relative;
    border-radius: 11px;
}

.c-tmpl-card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    position: relative;
}

.c-tmpl-container {
    position: relative;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    background-color: rgba(37, 43, 40, 0.14);
    width: 100%;
    display: table;
    position: absolute;
    bottom: 0px;
    z-index: -0.9;
    border-radius: 11px;
}

.c-tmpl-p {
    color: #ecf0f1;
    padding: 0px 5px;
    text-shadow: 1px 1px 5px black, 2px 2px 5px black;
    margin-top: 2px;
    font-size: 16px;
    margin-block-end: 0em;
}

.c-tmpl-text {
    position: relative;
    float: left;
    width: 80%;
}

.c-tmpl-arrow {
    position: relative;
    border-left: 1px solid white;
    top: 5px;

    position: relative;
    float: right;
    color: white;
    width: 18%;
    text-shadow: 1px 1px 1px #000;
}

.c-tmpl-ars {
    position: relative;
    margin: 50% 25%;
    font-weight: 600;
}

.c-tmpl-block {
    position: relative;
    display: block;
}

.c-tmpl-a .c-tmpl-img {
    border-radius: 11px;
}

.c-tmpl-cursor {
    position: fixed;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    pointer-events: none;
    z-index: 9999;
}

.c-tmpl-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: red;
}

.c-tmpl-pulse {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid rgba(0, 0, 0, 0.3);
    animation: pulse 1s ease-in-out infinite;
    border-color: red;
}

@keyframes pulse {
    0% {
        transform: scale(0.8);
        opacity: 1;
    }

    100% {
        transform: scale(1.2);
        opacity: 0;
    }
}